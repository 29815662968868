export const firebaseConfig = {

	apiKey: "AIzaSyDOFak6fgsd166nvhbEzjJEGrKmKZ2Lsnw",
  authDomain: "animetron-4027a.firebaseapp.com",
  databaseURL: "https://animetron-4027a-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "animetron-4027a",
  storageBucket: "animetron-4027a.appspot.com",
  messagingSenderId: "527803649706",
  appId: "1:527803649706:web:3702a251238315681f4ad3",
  measurementId: "G-09PHNGQFKQ"
};

	

	

	

	

	

	

	

	
